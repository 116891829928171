<template>
  <div class="show-credit">
    <!-- <NavBar @click="goback" title="授信展示页" /> -->
    <div class="background"></div>
    <div class="box box-one">
      <div class="top">
        <span>您的预计放款额度</span>
        <span>有效期至：{{ validityPeriodText }}</span>
      </div>
      <div class="center">{{ isExpired ? '额度失效' : '￥' + quota }}</div>
      <div class="bottom">
        该额度以上传数据维度测试为准，账期+7天数,订单已发货且已揽收且未结算的总金额，
        具体放款金额以实际合作实时数据为准。
      </div>
    </div>
    <div class="box box-two">
      <div class="form">
        <div class="from-item">
          <div class="label">账期(天):</div>
          <div class="value">{{ paymentperiod }}</div>
        </div>
        <div class="from-item">
          <div class="label">分佣比例(%):</div>
          <div class="value">{{ commissionratio }}</div>
        </div>
        <div class="from-item">
          <div class="label">实际折扣率(%):</div>
          <div class="value">{{ actualLoanDiscountRate }}</div>
        </div>
        <div class="from-item">
          <div class="label">退款率(%):</div>
          <div class="value">{{ refundrate }}</div>
        </div>
        <div class="from-item">
          <div class="label">店铺:</div>
          <div class="value">{{ dianpu }}({{ stok }})
          </div>
        </div>
        <div class="from-item">
          <div class="label">客单价(元):</div>
          <div class="value">{{ customerUnitPrice }}
          </div>
        </div>
        <div class="from-item">
          <div class="label">近三月订单数:</div>
          <div class="value">{{ pastThreeMonthsOrderNumber }}
          </div>
        </div>
        <div class="from-item">
          <div class="label">近三月订单金额:</div>
          <div class="value">{{ pastThreeMonthsOrderAmount }}
          </div>
        </div>
      </div>
    </div>
    <div class="btn-box">
      <el-button type="primary" class="btn" @click="applyForQuotaImmediately"
          v-if="Number(shopflom) === 1 && Number(status) !== 4">立即申请额度</el-button>
    </div>
    <div class="btn-boxs">
      <p  style="cursor: pointer;"
        @click="resetOperation" Loading>重新试算</p><br>
    </div>
    <div class="btn-boxss">   
      <p    style="cursor: pointer;"
        @click="connect" Loading>额度太低,联系我们</p>
    </div>
    <!-- 弹窗 -->
    <div class="phone-dialog" v-if="showPhoneDialog" @click="closePhoneDialog">
      <div class="content" v-if="isMobile">
        <div class="phone">{{ phone }}</div>
        <div class="button-box">
          <el-button type="text" class="btn" @click="call">拨打</el-button>
          <el-button type="text" class="btn" @click="closePhoneDialog">取消</el-button>
        </div>
      </div>
    </div>

    <div class="phone-dialog" v-if="showPhoneDialoga" @click="closePhoneDialog">
      <div class="content" v-if="isMobile">
        <div class="phone">电话:{{ phone }}</div>
        <div class="button-box">
          <el-button type="text" class="btn2" @click="closePhoneDialoga">取消</el-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script setup>
import { ElMessage } from 'element-plus';
import { ref, computed, onMounted, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

const router = useRouter()
const route = useRoute()
const validityPeriod = ref()
const validityPeriodText = ref('')
const smsUuid = route.query.smsUuid
const merchantId = ref()
const data = ref({})
const isLoading = ref(true)
const status = ref()
//放款金额
const quota = ref() // route.query.datas.quota
//联系电话
const phone = ref() // route.query.datas.phone
//账期
const paymentperiod = ref() // route.query.datas.paymentperiod
//分佣比例
const commissionratio = ref() // route.query.datas.commissionratio
//退款率
const refundrate = ref() // route.query.datas.refundrate
//实际折扣率
const actualLoanDiscountRate = ref()
//客单价
const customerUnitPrice = ref()
//月订单金额
const pastThreeMonthsOrderAmount = ref()
//月订单数量
const pastThreeMonthsOrderNumber = ref()
const userMobile = ref()
//店铺名加平台
const stok = ref()
const dianpu = ref()
const channelId = ref()

const shopflom = ref()


const controlDisabled = ref()
async function fetchData() {
  isLoading.value = true
  const headers = new Headers();
  headers.append('tenant-id', '1');
  // 发送请求
  const result = await fetch('/admin-api/incoming/credit-calculation/getByUuid?smsUuid=' + smsUuid, {
    method: 'get',
    headers: headers,
  }).then(req => req.json())
  if (result.data) {
    quota.value = ((result.data.estimatedAmount) * 10000).toFixed(0)
    phone.value = result.data.channelPhone
    paymentperiod.value = result.data.paymentPeriod
    commissionratio.value = result.data.commissionRatio
    refundrate.value = result.data.refundRate
    dianpu.value = result.data.shopName
    actualLoanDiscountRate.value = result.data.actualLoanDiscountRate
    channelId.value = result.data.channelId
    userMobile.value = result.data.phoneNumber
    merchantId.value = result.data.merchantId
    customerUnitPrice.value = result.data.customerUnitPrice
    pastThreeMonthsOrderAmount.value = result.data.pastThreeMonthsOrderAmount
    pastThreeMonthsOrderNumber.value = result.data.pastThreeMonthsOrderNumber
    shopflom.value = Number(result.data.shopType)
    if (result.data.exceptionMessage) {
      ElMessage.error(result.data.exceptionMessage)
    }
    if (result.data.failureTime) {
      const date = new Date(result.data.failureTime[0], result.data.failureTime[1] - 1, result.data.failureTime[2])
      date.setDate(date.getDate())
      validityPeriod.value = date
      validityPeriodText.value = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    }
    data.value = result.data
    if (merchantId.value) {
      statusbymerchantId()
    }
  }
  const headersa = new Headers();
  // headers.append('Content-Type', 'application/x-www-form-urlencoded');
  headersa.append('tenant-id', '1');
  // 发送请求
  fetch('/admin-api/system/dict-data/list-getMerchant-simple', {
    method: 'Get',
    headers: headersa,
  })
    .then(response => response.json())
    .then(datas => {
      const labels = datas.data.filter(item => item.dictType === 'merchant_shop_platform')
      labels.forEach(item => {
        if (Number(item.value) === Number(result.data.shopType)) {
          stok.value = item.label
        }
      });
      // 在这里处理响应数据
    })
    .catch(error => {
      console.error('Error:', error);
      // 在这里处理错误
    });

  controlDisabled.value = 1
  isLoading.value = false

}

fetchData()
const showPhoneDialog = ref(false)
const showPhoneDialoga = ref(false)

const isMobile = ref(true)

// 计算是否过期
const isExpired = computed(() => {
  return validityPeriod.value ? validityPeriod.value.getTime() < Date.now() : ''

});
//通过商户id获取商户审批状态
const statusbymerchantId = () => {

  const headersa = new Headers();
  headersa.append('tenant-id', '1');
  fetch('/admin-api/incoming/merchant-service/getbyId?id=' + merchantId.value, {
    method: 'Get',
    headers: headersa,
  })
    .then(response => response.json())
    .then(datas => {
      if (datas) {
        status.value = datas.data.status
      } else {
        status.value = 0
      }

    })
    .catch(error => {
      console.error('Error:', error);
      // 在这里处理错误
    });
}
// 联系我们
const connect = () => {
  // 判断是PC端还是移动端
  if (/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)) {
    showPhoneDialog.value = true
  } else {
    showPhoneDialoga.value = true
  }
}
// 关闭弹窗
const closePhoneDialog = () => {
  showPhoneDialog.value = false
}
const closePhoneDialoga = () => {
  showPhoneDialoga.value = false
}

// 拨打
const call = () => {
  window.location.href = `tel:${phone.value}`;
}

// 重新运算
const resetOperation = () => {
  // 把店铺和企业信息带过去
  router.push({
    path: '/storeInfo',
    query: {
      channelId: channelId.value,
      userMobile: userMobile.value,
    }
  })
}

// 返回
const goback = () => {
  router.push('/loading')
}
const applyForQuotaImmediately = () => {
  router.push({
    path: '/uploadOfIncomingMaterials',
    query: {
      channelId: channelId.value,
      merchantId: merchantId.value,
      smsUuid: smsUuid
    }
  })
}

// 在组件挂载后监听 isMobile 的变化
onMounted(() => {
  // 监听 isMobile 的变化
  watch(() => isMobile.value, () => {
    // 在 isMobile 变化时执行一些逻辑

    // 重新渲染整个页面
    router.go(0);
  });
});

</script>

<style lang="scss" scoped>
.show-credit {
  display: flex;
  flex-direction: column;
  align-items: center;

  .background {
    width: 100%;
    height: 15.31rem;
    background-color: #4095e5;
    z-index: -1;
    font-size: 1.43rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-family: PingFangSC-regular;
  }

  .box {
    width: 22.13rem;
    background-color: #ffffff;
    border-radius: 0.62rem;
    box-shadow: 0 0.3rem 0.5rem 0 rgba(0, 0, 0, 0.3);
    padding: 0 1.875rem 1.875rem 1.875rem;
  }

  .box-one {
    margin-top: -13.12rem;
    padding: 0.75rem;

    .top {
      font-size: 0.875rem;
      color: #101010;
      display: flex;
      justify-content: space-between;
    }

    .center {
      color: #4095e5;
      font-size: 2.5rem;
      text-align: center;
    }

    .bottom {
      font-size: 0.687rem;
      color: #858484;
      text-align: center;
      margin-top: 0.75rem;
    }
  }

  .box-two {
    padding: 2rem 2.625rem;
    margin-top: 1.43rem;

    .from-item {
      display: flex;
      line-height: 2.51rem;
      font-size: 0.88rem;
      justify-content: space-between;
      align-items: center;

      .label {
        color: #4f4f4f;
        font-family: PingFangSC-regular;
      }

      .input:focus {
        outline: none;
      }

      .select {
        width: 10.31rem;
        font-family: PingFangSC-regular;
        border: none;
      }
    }
  }

  .jump {
    color: #4095e5;
    font-size: 1rem;
    font-family: PingFangSC-regular;
    margin-top: 3rem;
    display: block;
    text-align: center;
  }

  .btn-box {
    display: flex;
    justify-content: center;
    margin-top: 3rem;

    .btn {
      width: 14.56rem;
      height: 2.45rem;
      border-radius: 1rem;
      background-color: #0581fe;
      font-family: PingFangSC-regular;
 
    }
  }
  .btn-boxs {
    display: flex;
    justify-content: center;
    margin-top: 1rem;

    .btn {
      width: 14.56rem;
      height: 2.45rem;
      border-radius: 1rem;
      background-color: #0581fe;
      font-family: PingFangSC-regular;
 
    }
  }
  .btn-boxss {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .btn {
      width: 14.56rem;
      height: 2.45rem;
      border-radius: 1rem;
      background-color: #0581fe;
      font-family: PingFangSC-regular;
 
    }
  }
  .phone-dialog {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #ffffff;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 13px 13px 13px 13px;
      width: 16.875rem;

      .img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        overflow: hidden;
      }

      .phone {
        width: 100%;
        height: 2.75rem;
        border-radius: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #030303;
        font-size: 1.125rem;
        font-family: PingFangSC-regular;
        padding: 1.8rem 0;
      }

      .button-box {
        display: flex;
        width: 100%;
        border-top: #9ea6b7 1px solid;

        .btn {
          color: #0076FF;
          font-size: 1.05rem;
          font-family: PingFangSC-regular;
          height: 2.5rem;
          width: 50%;
          text-align: center;

          &:first-child {
            border-right: #9ea6b7 1px solid;
          }
        }

        .btn2 {
          color: #0076ff;
          font-size: 1.05rem;
          font-family: PingFangSC-regular;
          height: 2.5rem;
          width: 100%;
          text-align: center;

          &:first-child {
            border-right: #9ea6b7 1px solid;
          }
        }
      }
    }
  }
}
</style>
